import paxyLogo from './logo-paxy.svg';
import tacoIcon from './taco-svgrepo-com.svg';
import './App.css';
import { GoogleLogin } from 'react-google-login';
import GoogleButton from 'react-google-button'
import React from "react";

function GoogleSignin() {
  
  function responseGoogle (response) {
    console.log(response);
    if (response) {
      window.location.href = 'https://taco.pmax.io'; 

    }
  }

  function responseGoogleError (response) {
    console.log(response);
  }

  return (
    <div className="App">
      <header className="App-header">
        <div>
          <img src={paxyLogo} className="App-logo" alt="logo" />
          <img src={tacoIcon} className="App-logo" alt="logo" />
        </div>

        <GoogleLogin        
          clientId="1023558529972-oljvpkleubqh89jq36r5ok6cpeacf7f2.apps.googleusercontent.com"
          render={renderProps => (
            <GoogleButton
                onClick={renderProps.onClick}
            />
          )}
          onSuccess={responseGoogle}
          onFailure={responseGoogleError}
          cookiePolicy={'single_host_origin'}
          scope="profile email openid https://www.googleapis.com/auth/script.external_request"        
      />       
        
      </header>
    </div>
  );  
}

export default GoogleSignin;

